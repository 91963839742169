function showLog(str) {
    //console.log(str)
}

// ************** LEVEL / TEMPLATE ID SPECIFIC FUNCTIONS ************************
// modify these functions to add options here ****
// ******************************************************************************
//
// *** select only the necessary options for the level / template **********
export function getOptionsForCase(templateID, courseid = null) {
    showLog("[getOptionsForCase] templateID: " + templateID + " courseid: " + courseid)

    const Test = {
        "category": "test",
        "options": ['test-object1', 'test-object2']
    }
    
    const Sample1RomanNumeral = {
        "category": "compound-romanNumeral",
        "options": ["compound-RomanNumeralVMajor","compound-RomanNumeralvMinor", "compound-RomanNumeralIMajor", "compound-RomanNumeraliMinor", "compound-RomanNumeral42", "compound-RomanNumeral43", "compound-RomanNumeral64", "compound-RomanNumeral65"]
    }

    const Sample1Notes = {
        "category": "notes",
        "options": ['note-whole', 'note-half', 'note-quarter', 'note-eighth', 'note-sixteenth']
    }

    
    const TestFunctionalChordSymbols = {
        "category": "functionalChordSymbols",
        "options": ['functionalChordSymbols-IMajor','functionalChordSymbols-iMinor', 'functionalChordSymbols-IVMajor', 'functionalChordSymbols-ivMinor', 'functionalChordSymbols-VMajor']
    }

    const Test1RootQualityChordSymbols = {
        "category": "rootQualityChordSymbols",
        "options": ['rootQualityChordSymbols-C', 'rootQualityChordSymbols-D', 'rootQualityChordSymbols-F', 'rootQualityChordSymbols-Fsharp', 'rootQualityChordSymbols-G', 'rootQualityChordSymbols-G7', 'rootQualityChordSymbols-A','rootQualityChordSymbols-A7', 'rootQualityChordSymbols-B' , 'rootQualityChordSymbols-Bflat']
    }

    const TestMarkingSymbol = {
        "category": "markingSymbol",
        "options": ['markingSymbol-X']
    }

    const TestQuality = {
        "category": "quality",
        "options": ['quality-m']
    }

    // PROD templates

    const MarkingSymbol = {
        "category": "markingSymbol",
        "options": ['markingSymbol-X']
    }

    const LADirection = {
        "category": "direction",
        "options": ["direction-L","direction-R"]
    }

    const LAFinger = {
        "category": "prepfinger",
        "options": ["prepfinger-1","prepfinger-2", "prepfinger-3", "prepfinger-4", "prepfinger-5"]
    }


    const L1Counts = {
        "category": "counts",
        "options": ["counts-1","counts-2", "counts-3", "counts-4", "counts-plus"]
    }

    const L1Finger = {
        "category": "finger",
        "options": ["finger-1","finger-2", "finger-3", "finger-4", "finger-5"]
    }

    const L1Dynamics = {
        "category": "dynamics",
        "options": ["dynamics-p", "dynamics-mp", "dynamics-mf", "dynamics-f"]
    }

    const L2Dynamics = {
        "category": "dynamics",
        "options": ["dynamics-pp", "dynamics-p", "dynamics-mp", "dynamics-mf", "dynamics-f", "dynamics-ff"]
    }

    const L2RootQualityChordSymbols = {
        "category": "rootQualityChordSymbols",
        "options": ['rootQualityChordSymbols-C', 'rootQualityChordSymbols-D', 'rootQualityChordSymbols-E', 'rootQualityChordSymbols-F', 'rootQualityChordSymbols-G', 'rootQualityChordSymbols-A', 'rootQualityChordSymbols-B']
    }

    const L2Quality = {
        "category": "quality",
        "options": ['quality-m']
    }

    const L1Accidentals = {
        "category": "accidentals",
        "options": ['accidentals-sharp','accidentals-flat', 'accidentals-natural']
    }

    const L3FunctionalChordSymbols = {
        "category": "functionalChordSymbols",
        "options": ['functionalChordSymbols-IMajor','functionalChordSymbols-VMajor', 'functionalChordSymbols-iMinor']
    }

    const L3RootQualityChordSymbols = {
        "category": "rootQualityChordSymbols",
        "options": ['rootQualityChordSymbols-C', 'rootQualityChordSymbols-D', 'rootQualityChordSymbols-E', 'rootQualityChordSymbols-F', 'rootQualityChordSymbols-G', 'rootQualityChordSymbols-A', 'rootQualityChordSymbols-Bflat', 'rootQualityChordSymbols-B' ]
    }

    const L4FunctionalChordSymbols = {
        "category": "functionalChordSymbols",
        "options": ['functionalChordSymbols-IMajor', 'functionalChordSymbols-iMinor', 'functionalChordSymbols-IVMajor', 'functionalChordSymbols-ivMinor', 'functionalChordSymbols-VMajor']
    }

    const L3Quality = {
        "category": "quality",
        "options": ['quality-m']
    }

    const L5Dynamics = {
        "category": "dynamics",
        "options": ["dynamics-pp", "dynamics-p", "dynamics-mp", "dynamics-mf", "dynamics-f", "dynamics-ff", "dynamics-accent", "dynamics-cresc", "dynamics-dim"]
    }

    const L5ColouredCircles = {
        "category": "colouredCircles",
        "options": ['colouredCircles-red','colouredCircles-green','colouredCircles-yellow','colouredCircles-purple']
    }

    const L5BeatPatterns = {
        "category": "beatPatterns",
        "options": ['beatPatterns-S', 'beatPatterns-M', 'beatPatterns-w']
    }

    const L5Counts = {
        "category": "counts",
        "options": ["counts-1","counts-2", "counts-3", "counts-4","counts-5","counts-6", "counts-plus"]
    }

    const L5RootQualityChordSymbols = {
        "category": "compound-rootQualityChordSymbols",
        "options": [ "compound-rootQualityChordSymbolsA","compound-rootQualityChordSymbolsB","compound-rootQualityChordSymbolsC","compound-rootQualityChordSymbolsD","compound-rootQualityChordSymbolsE","compound-rootQualityChordSymbolsF","compound-rootQualityChordSymbolsG","compound-rootQualityChordSymbolsSharp","compound-rootQualityChordSymbolsFlat","compound-rootQualityChordSymbolsm","compound-rootQualityChordSymbols7"]
    }

    const L5Intervals = {
        "category": "compound-intervals",
        "options": [ "compound-intervals1","compound-intervals2","compound-intervals3","compound-intervals4","compound-intervals5","compound-intervals6","compound-intervals7","compound-intervals8","compound-intervalsmaj","compound-intervalsmin","compound-intervalsper"]
    }

    const L5FunctionalChordSymbols = {
        "category": "compound-functionalChordSymbols",
        "options": ["compound-functionalChordSymbolsIMajor","compound-functionalChordSymbolsiMinor","compound-functionalChordSymbolsVMajor", "compound-functionalChordSymbolsvMinor", "compound-functionalChordSymbols7"]
    }

    const L6Dynamics = {
        "category": "dynamics",
        "options": ["dynamics-pp", "dynamics-p", "dynamics-mp", "dynamics-mf", "dynamics-f", "dynamics-ff", "dynamics-accent", "dynamics-cresc", "dynamics-dim", "dynamics-sfz"]
    }

    const L6RootQualityChordSymbols = {
        "category": "compound-rootQualityChordSymbols",
        "options": [ "compound-rootQualityChordSymbolsA","compound-rootQualityChordSymbolsB","compound-rootQualityChordSymbolsC","compound-rootQualityChordSymbolsD","compound-rootQualityChordSymbolsE","compound-rootQualityChordSymbolsF","compound-rootQualityChordSymbolsG","compound-rootQualityChordSymbolsSharp","compound-rootQualityChordSymbolsFlat","compound-rootQualityChordSymbolsSlash","compound-rootQualityChordSymbolsm","compound-rootQualityChordSymbols7"]
    }

    const L6Intervals = {
        "category": "compound-intervals",
        "options": [ "compound-intervals1","compound-intervals2","compound-intervals3","compound-intervals4","compound-intervals5","compound-intervals6","compound-intervals7","compound-intervals8","compound-intervalsmaj","compound-intervalsmin","compound-intervalsper","compound-intervalsaug","compound-intervalsdim"]
    }

    const L6FunctionalChordSymbols = {
        "category": "compound-functionalChordSymbols",
        "options": ["compound-functionalChordSymbolsIMajor","compound-functionalChordSymbolsiMinor","compound-functionalChordSymbolsVMajor", "compound-functionalChordSymbolsvMinor", "compound-functionalChordSymbolsdim",  "compound-functionalChordSymbols7"]
    }

    const L7FunctionalChordSymbols = {
        "category": "compound-functionalChordSymbols",
        "options": ["compound-functionalChordSymbolsIMajor","compound-functionalChordSymbolsiMinor","compound-functionalChordSymbolsVMajor", "compound-functionalChordSymbolsvMinor","compound-functionalChordSymbolsaug",  "compound-functionalChordSymbolsdim", "compound-functionalChordSymbols6", "compound-functionalChordSymbols64", "compound-functionalChordSymbols7", "compound-functionalChordSymbols65", "compound-functionalChordSymbols43", "compound-functionalChordSymbols42"]
    }

    const L7RootQualityChordSymbols = {
        "category": "compound-rootQualityChordSymbols",
        "options": [ "compound-rootQualityChordSymbolsA","compound-rootQualityChordSymbolsB","compound-rootQualityChordSymbolsC","compound-rootQualityChordSymbolsD","compound-rootQualityChordSymbolsE","compound-rootQualityChordSymbolsF","compound-rootQualityChordSymbolsG","compound-rootQualityChordSymbolsSharp","compound-rootQualityChordSymbolsFlat","compound-rootQualityChordSymbolsSlash","compound-rootQualityChordSymbolsm","compound-rootQualityChordSymbolsaug","compound-rootQualityChordSymbolsdim","compound-rootQualityChordSymbols7"]
    }

    const L8Intervals = {
        "category": "compound-intervals",
        "options": [ "compound-intervals1","compound-intervals2","compound-intervals3","compound-intervals4","compound-intervals5","compound-intervals6","compound-intervals7","compound-intervals8","compound-intervals9","compound-intervals10","compound-intervalsmaj","compound-intervalsmin","compound-intervalsper","compound-intervalsaug","compound-intervalsdim"]
    }

    const L8FunctionalChordSymbols = {
        "category": "compound-functionalChordSymbols",
        "options": ["compound-functionalChordSymbolsIMajor","compound-functionalChordSymbolsiMinor","compound-functionalChordSymbolsVMajor", "compound-functionalChordSymbolsvMinor", "compound-functionalChordSymbolsaug", "compound-functionalChordSymbolsdim", "compound-functionalChordSymbolshalfdim", "compound-functionalChordSymbols6", "compound-functionalChordSymbols64", "compound-functionalChordSymbols7", "compound-functionalChordSymbols65", "compound-functionalChordSymbols43", "compound-functionalChordSymbols42", "compound-functionalChordSymbolsSlash"]
    }

    const L9FunctionalChordSymbols = {
        "category": "compound-functionalChordSymbols",
        "options": ["compound-functionalChordSymbolsIMajor","compound-functionalChordSymbolsiMinor","compound-functionalChordSymbolsVMajor", "compound-functionalChordSymbolsvMinor", "compound-functionalChordSymbolsdim", "compound-functionalChordSymbols6", "compound-functionalChordSymbols64", "compound-functionalChordSymbols7", "compound-functionalChordSymbols65", "compound-functionalChordSymbols43", "compound-functionalChordSymbols42", "compound-functionalChordSymbolsSlash"]
    }

    const L9Counts = {
        "category": "counts",
        "options": ["counts-1","counts-2", "counts-3", "counts-4","counts-5","counts-6","counts-7","counts-8","counts-9", "counts-plus"]
    }

    const L10RootQualityChordSymbols = {
        "category": "compound-rootQualityChordSymbols",
        "options": [ "compound-rootQualityChordSymbolsA","compound-rootQualityChordSymbolsB","compound-rootQualityChordSymbolsC","compound-rootQualityChordSymbolsD","compound-rootQualityChordSymbolsE","compound-rootQualityChordSymbolsF","compound-rootQualityChordSymbolsG","compound-rootQualityChordSymbolsSharp","compound-rootQualityChordSymbolsFlat","compound-rootQualityChordSymbolsSlash","compound-rootQualityChordSymbolsm","compound-rootQualityChordSymbolsaug","compound-rootQualityChordSymbolsdim","compound-rootQualityChordSymbolsHalfDim","compound-rootQualityChordSymbols7"]
    }


    switch (templateID) {
        case 'test':
            return [Test, L1Counts, L8Intervals, L7RootQualityChordSymbols]
        case "small":
        case "medium":
        case "large":
        case "test1":
            return [L1Accidentals, TestFunctionalChordSymbols, Test1RootQualityChordSymbols, TestMarkingSymbol, Sample1RomanNumeral]
        case "test2":
            return [L1Finger, L1Counts, L1Dynamics, TestQuality]
  
        case "LAT2":
            return [LADirection]
        case "LASP":
            return [LAFinger, L1Counts]
        case "LAT1":
            return [LAFinger]
        case "LARR":
            return [L1Counts]
        case "L1T1":
            return [L1Finger]
        case "L1T2":
            return [L1Counts]
        case "L1RR":
            return [MarkingSymbol, L1Counts]
        case "L1T4":
            return [L1Dynamics]
        case "L1T5":
            return [L1Accidentals]
        case "L1SP":
            return [MarkingSymbol, L1Counts, L1Finger, L1Accidentals]
        case "L2T1":
            return [MarkingSymbol]
        case "L2T2":
            return [L2Dynamics]
        case "L2T4":
            return [L2RootQualityChordSymbols, L2Quality]
        case "L2SP":
            return [MarkingSymbol, L1Counts, L1Finger, L1Accidentals, L2RootQualityChordSymbols, L3Quality]   
        case "L3T1":
            return [L3FunctionalChordSymbols]
        case "L3SP":
            return [MarkingSymbol, L1Counts, L1Finger,L1Accidentals, L3RootQualityChordSymbols, L3Quality, L3FunctionalChordSymbols]
        case "L4T1":
            return [L4FunctionalChordSymbols]
        case "L4SP":
            return [MarkingSymbol, L1Counts, L1Finger, L1Accidentals, L3RootQualityChordSymbols, L3Quality, L4FunctionalChordSymbols]
        case "L5T1":
            return [L5Dynamics]
        case "L5T2":
            return [L5RootQualityChordSymbols]
        case "L5T3":
            return [L5ColouredCircles]
        case "L5T4A":
            return [L5ColouredCircles, MarkingSymbol]
        case "L5T4":
            return [L5Intervals]
        case "L5T5":
            return [L5BeatPatterns]
        case "L5T6":
            return [L5Counts]
        case "L5T7":
            return [L5Counts, L5BeatPatterns]
        case "L5-6T1":
            return [L5FunctionalChordSymbols]
        case "L5RR":
            return [MarkingSymbol, L5Counts, L5BeatPatterns]
        case "L5SP":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L5RootQualityChordSymbols, L5FunctionalChordSymbols]
        case "L5LS":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L5FunctionalChordSymbols]
        case "L6T1":
            return [L6Dynamics]
        case "L6T2":
            return [L6RootQualityChordSymbols]
        case "L6T4":
            return [L6Intervals]
        case "L6SP":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals,L6RootQualityChordSymbols, L5FunctionalChordSymbols]
        case "L6LS":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L5FunctionalChordSymbols]
        case "L7T1":
            return [L7FunctionalChordSymbols]
        case "L7T2":
            return [L7RootQualityChordSymbols]
        case "L7SP":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals,L7RootQualityChordSymbols, L7FunctionalChordSymbols]
        case "L7LS":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L6FunctionalChordSymbols]
        case "L8T1":
            return [L8Intervals]
        case "L8LS":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L7FunctionalChordSymbols]

        case "L9T1":
            return [L9FunctionalChordSymbols]
        case "L9SP":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L7RootQualityChordSymbols, L9FunctionalChordSymbols]
        case "L9LS":
            return [MarkingSymbol, L5ColouredCircles, L9Counts, L1Finger, L1Accidentals, L8FunctionalChordSymbols]
        case "L10T1":
            return [L10RootQualityChordSymbols]
        case "L10SP":
            return [MarkingSymbol, L5ColouredCircles, L5Counts, L1Finger, L1Accidentals, L10RootQualityChordSymbols, L9FunctionalChordSymbols]
        case "L10LS":
            return [MarkingSymbol, L5ColouredCircles, L9Counts, L1Finger, L1Accidentals, L8FunctionalChordSymbols]
         default:
            return [];
    }
}

export function getAllUIBUttonIDs(options) {
    showLog("[getAllUIBUttonIDs]")
    var categories = []

    for (var i = 0;  i < options.length; i++) {
        const option = options[i]
        showLog("option: " + JSON.stringify(option))
        categories.push(option.category)
    }
    const firstButton = ['pen'] 
    const lastButton = ['delete']
    const allUIButtons = firstButton.concat(categories).concat(lastButton)
    return allUIButtons
}

export function preloadQuizImage(baseurl, id, number, scene) {
    showLog("[preloadQuizImage] baseurl: " + baseurl + " id: " + id + " number: " + number)
    const url = (number == null || number == undefined || number == "undefined") ? baseurl + id + ".png" : baseurl + id + "_Q" + number + ".png"
    showLog("url path from whiteboard: " + url);
    scene.load.image('quizImage', url + "?cacheblock=true");
}

export function preloadUtilitiesImages(namesArr, baseURL, scene) {
    showLog("[preloadUIImages] namesArr: " + namesArr)
    for (let i = 0; i < namesArr.length; i++) {
        const id = namesArr[i];
        showLog("id: " + id)
        scene.load.image(id, baseURL + id + '.png');
    }
}

export function preloadUIImages(namesArr, baseURL, scene) {
    showLog("[preloadUIImages] namesArr: " + namesArr)
    for (let i = 0; i < namesArr.length; i++) {
        const id = namesArr[i];
        showLog("id: " + id)
        scene.load.image(id, baseURL + 'ui-' + id + '.png');
        scene.load.image(id + "-active", baseURL + 'ui-' + id + '-active.png');
    }
}



export function preloadPopupImages(options, basePopupURL, baseLabelsURL, scene) {
    showLog("[preloadPopupImages] options: " + JSON.stringify(options))
    const size = scene.labelSize
    for (let i = 0; i < options.length; i++) {
        const id = options[i].category;
        //const items = getPopupItems(id)
        const items = options[i].options
        for (let j = 0; j < items.length; j++) {
            const item = items[j];
         scene.load.image(item,basePopupURL + 'btnPopup-' + item + '.png');
         scene.load.image("label-" + item, baseLabelsURL + 'label-' + item + '-' + size + '.png');
        }
    }
}

export function preloadPopupBox(scene,baseURL) {
    showLog('[preloadPopupBox]')
    const popupParts = ['blank', 'bottom-left', 'bottom-right', 'bottom', 'left', 'right','top-left','top-right','top']
    const popupButtons = ['undo', 'create']
    for (let i = 0; i < popupParts.length; i++) {
        const part = popupParts[i];
        scene.load.image('popup-' + part, baseURL + part + '.png');
    }
    for (let i = 0; i < popupButtons.length; i++) {
        const button = popupButtons[i];
        scene.load.image(button + 'Button', baseURL + button + '.png');
    }
}

export function setQuizAssets(templateID, scene) {
    showLog("[setQuizAssets] templateID: " + templateID)
    getOptionsForCase(templateID).map((option)=> {
        showLog("options are : " + option.category + ": " + option.options)
        scene.allObjectBtns = scene.allObjectBtns.concat(option.options)
    })
}