import Phaser from 'phaser'
import ToolButton from './ToolButton'



export default class ToolButtonsContainer extends Phaser.GameObjects.Rectangle {
    constructor(scene, x,y,width,height){
        super(scene, x, y, width, height)
        this.toolButtonsContainer = null
        this.toolButtons = []
        this.setToolButtonsContainer(scene, x, y, width, height, this)
    }

    setToolButtonsContainer(scene,x, y, width, height, parent){
        //scene.showLog("[setToolButtonsContainer] width: " + width + " height: " + height)
        var toolButtonsContainer = scene.add.rectangle(x, y, width, height, 0xffffff, 1).setInteractive();
        //toolButtonsContainer.setStrokeStyle(4, 0xefc53f);
        parent.toolButtonsContainer = toolButtonsContainer
        this.setToolButtons(scene, parent, x - (width / 2),y,width)
    }

    setToolButtons(scene,parent, x,y, width) {
        
        //var buttonSize = 72
        var eachArea = width / scene.toolButtons.length

        for (let i = 0; i < scene.toolButtons.length; i++) {
            const buttonID = scene.toolButtons[i];
            //scene.showLog("buttonID: " + buttonID)
            var toolButton = new ToolButton(scene,x + (eachArea * i) + (eachArea / 2),y,eachArea,eachArea,buttonID, null)
            
            //toolButton.setStrokeStyle(4, 0xefc53f);
            parent.toolButtons.push(toolButton)
        }
    }
}