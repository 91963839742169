import Phaser from 'phaser'
import {handleClick, getResizeRatioByWidth} from '../helper/Util'

export default class ToolButton extends Phaser.GameObjects.Sprite {
    constructor(scene, x,y,width,height, id, texture){
        super(scene, x, y, width, height)
        this.id = id
        this.toolbutton = null
        this.toolbuttonActive = null
        this.setToolButton(scene, x,y,width,height, id, this)
    }

    showLog(str){
        //console.log(str)
    }

    setToolButton(scene, x,y,width,height, id, parent) {
        //parent.showLog("[setToolButton] width: " + width + " height: " + height + " id: " + id)

        var toolbutton = scene.add.sprite(x, y, id).setInteractive()
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
                handleClick(id, scene)
                scene.clicked = true
            }, scene)
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
                scene.clicked = false
            },scene)

        this.showLog("toolbutton.width: " + toolbutton.width)

        /*
        toolbutton.displayWidth = width - 2
        toolbutton.displayHeight = height - 2
        */

        toolbutton.setScale(getResizeRatioByWidth(toolbutton,width * 0.8));

        parent.toolbutton = toolbutton

        var toolbuttonActive = scene.add.sprite(x, y, id+"-active").setInteractive()
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
                handleClick(id, scene)
                scene.clicked = true
            }, scene)
            .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
                scene.clicked = false
            },scene)

            toolbuttonActive.setScale(getResizeRatioByWidth(toolbutton,width * 0.8));

            /*
            toolbuttonActive.displayWidth = width - 2
            toolbuttonActive.displayHeight = height - 2
            */
        parent.toolbuttonActive = toolbuttonActive
    }
    toggleActive(bool) {
        this.toolbuttonActive.visible = bool
        this.toolbutton.visible = !bool
    }
}